export default function() {
  const trigger = document.getElementById("search-trigger");
  if (!trigger) {
    return;
  }

  const overlay = document.getElementById("search-overlay");
  if (!overlay) {
    return;
  }

  const input = overlay.querySelector(".header-search__input");
  if (!input) {
    return;
  }

  trigger.addEventListener("click", () => {
    overlay.classList.toggle("visible");
    input.focus();
  });
  overlay.addEventListener("click", (e) => {
    if(e.target.id === "search-overlay") {
      overlay.classList.remove("visible");
    }
  });
  document.addEventListener("keydown", (e) => {
    if(e.key === "Escape") {
      overlay.classList.remove("visible");
    }
  });
}
