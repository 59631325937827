export default function () {
  const container = document.querySelector("aside.quick-contact");
  if (container) {
    const button = container.querySelector(".contact-button");
    if (button) {
      button.addEventListener("click", () => {
        const state = container.getAttribute("aria-expanded");
        container.setAttribute("aria-expanded", state === "true" ? "false" : "true");
      });
    }
    window.addEventListener("click", (e) => {
      if (container !== e.target && !container.contains(e.target)) {
        container.setAttribute("aria-expanded", "false");
      }
    });
  }
}
