export default function () {
  document.querySelectorAll("div.form-group").forEach((group) => {
    const input = group.querySelector("input, textarea");
    if (input.value) {
      group.classList.add("label-top");
    }
    input.addEventListener("blur", () => {
      if (input.value) {
        group.classList.add("label-top");
      } else {
        group.classList.remove("label-top");
      }
    });
  });
}
