import { removeAnimation, fireScrollAnimation } from "./animations";
import { FLEX_CONTAINER_ANIMATION } from "./flex-container";

export default function initTabs() {
  const tabContainers = document.querySelectorAll(".container-tabbed");
  tabContainers.forEach((container) => {
    const buttons = container.querySelectorAll(".container-tabbed-button");
    const content = container.querySelectorAll(".container-tabbed-content");

    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        buttons.forEach((btn) => btn.classList.remove("active"));
        content.forEach((con) => {
          con.classList.remove("active");
          con.querySelectorAll(".container-flex").forEach((flex) => {
            removeAnimation(flex.childNodes);
          });
        });
        button.classList.add("active");
        const contentId = `#${button.id}-content`;
        container.querySelector(contentId).classList.add("active");
        container.querySelectorAll(".container-flex").forEach((flex) => {
          fireScrollAnimation(flex.childNodes, FLEX_CONTAINER_ANIMATION);
        });
      });
    });
  });
}
