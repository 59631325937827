import { fireScrollAnimation } from "./animations";

export const FLEX_CONTAINER_ANIMATION = "flexContainerInView 300ms forwards ease-in-out";

export default function () {
  document.querySelectorAll(".container-flex").forEach((container) => {
    const cards = container.childNodes;
    fireScrollAnimation(cards, FLEX_CONTAINER_ANIMATION);
  });
}
