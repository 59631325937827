import initMegaMenu from "./modules/menu-mega";
import initSplides from "./modules/splide";
import initAccordeons from "./modules/accordeon";
import initTabs from "./modules/container-tabs";
import initTabArrows from "./modules/container-tab-arrows";
import initQuickContact from "./modules/quick-contact";
import initPreload from "./modules/preload";
import initFormLabel from "./modules/form-label";
import initFlexContainer from "./modules/flex-container";
import initSearchTrigger from "./modules/search";
import fsLightbox from "fslightbox";


import "../styles/main.scss";
import { logoScrollAnimation } from "./modules/animations";

document.addEventListener("DOMContentLoaded", function () {
  initMegaMenu();
  initSplides();
  initAccordeons();
  initTabs();
  initTabArrows();
  initQuickContact();
  initPreload();
  initFormLabel();
  initFlexContainer();
  initSearchTrigger();
  logoScrollAnimation("logos 1s forwards ease");
});
