function initAccordeons() {
  const accordeons = document.querySelectorAll(".accordeon-item-btn");

  function update(accordeon, toggle = false) {
    let expanded = accordeon.getAttribute("aria-expanded");
    if (toggle) {
      expanded = expanded === "true" ? "false" : "true";
      accordeon.setAttribute("aria-expanded", expanded);
    }

    if (expanded === "false") {
      accordeon.parentNode.lastChild.setAttribute("hidden", "");
      accordeon.classList.remove("accordeon-active");
    } else {
      accordeon.parentNode.lastChild.removeAttribute("hidden");
      accordeon.classList.add("accordeon-active");
    }
  }

  accordeons.forEach((accordeon) => {
    update(accordeon);
    accordeon.addEventListener("click", () => update(accordeon, true));
  });
}

export default initAccordeons;
