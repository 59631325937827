import Splide from "@splidejs/splide";
import "@splidejs/splide/css";

function initSplides() {
  const splides = document.querySelectorAll(".ce-splide");

  splides.forEach((splideElement) => {
    //{autoplay: true, type: "loop"}
    const splide = new Splide(splideElement.querySelector(".splide"), { type: "loop", arrowPath: "M23.6326 11.5989C24.5738 10.7434 26.0303 10.8127 26.8859 11.7539L38.401 24.4205C39.0142 25.095 39.1719 26.0677 38.8031 26.9014C38.4343 27.735 37.6085 28.2727 36.6969 28.2727H3.30303C2.0311 28.2727 1 27.2416 1 25.9697C1 24.6978 2.0311 23.6667 3.30303 23.6667H31.4908L23.4777 14.8522C22.6221 13.9111 22.6915 12.4545 23.6326 11.5989Z" }).mount();
    splide.on("move", (nextIndex, prevIndex, dest) => {
      // slide new text down
      const prev = splideElement.querySelector(`.splide-text-container .splide-content[data-index="${prevIndex}"]`);
      prev.classList.add("hidden");
    });
    splide.on("moved", (nextIndex, prevIndex) => {
      // slide new text up
      const next = splideElement.querySelector(`.splide-text-container .splide-content[data-index="${nextIndex}"]`);
      next.classList.remove("hidden");
    });
  });
}

export default initSplides;
