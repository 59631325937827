function initMegaMenu() {
  const menu = document.querySelector("#nav-main");
  const menuWrapper = document.querySelector(".header-wrapper");
  const header = document.querySelector("header");
  const mobileSearch = document.querySelector(".navigation-main-mobile-search");
  if (!menu || !menuWrapper) {
    return;
  }

  const tabletWidth = getComputedStyle(document.documentElement).getPropertyValue("--width-tablet").replace("px", "");

  const state = {};

  const resetLevel = (level, hide = false) => {
    if (!state.hasOwnProperty(level)) {
      return;
    }

    for (const itemKey in state[level].items) {
      const el = state[level].items[itemKey];
      el.classList.remove("show");
    }

    for (const selector in state[level].selectors) {
      const el = state[level].selectors[selector];
      el.classList.remove("active");
    }

    menu.style.setProperty("--level", hide ? parseInt(level) - 1 : level);
    header.classList.remove("home");
  };

  const showItem = (itemId, level) => {
    if (!state.hasOwnProperty(level)) {
      return;
    }

    menuWrapper.classList.add("show");
    mobileSearch.classList.add("show");
    resetLevel(level);
    state[level].items[itemId].classList.add("show");
    state[level].selectors[itemId].classList.add("active");
  };

  const initLevel = (level) => {
    const items = menu.querySelectorAll(".l" + level + "-item");
    state[level] = { items: {}, selectors: {} };
    items.forEach((item) => {
      state[level].items[item.dataset.id] = item;
    });

    const selectors = menu.querySelectorAll(".l" + level + "-selector");
    selectors.forEach((item) => {
      item.addEventListener("click", (e) => {
        if (window.innerWidth <= tabletWidth || level < 2) {
          e.stopPropagation();
          e.preventDefault();
          showItem(item.dataset.id, level);
        }
      });
      if (level === 1) {
        item.addEventListener("mouseover", (e) => {
          if (window.innerWidth > tabletWidth) {
            e.stopPropagation();
            showItem(item.dataset.id, level);
          }
        });
        item.addEventListener("focus", (e) => {
          if (window.innerWidth > tabletWidth) {
            e.stopPropagation();
            showItem(item.dataset.id, level);
          }
        });
      }
      state[level].selectors[item.dataset.id] = item;
    });
  };

  initLevel(0);
  initLevel(1);
  initLevel(2);

  const mobileButton = menu.querySelector(".menu-burger");

  function closeMenu() {
    menuWrapper.classList.remove("show");
    resetLevel(2);
    resetLevel(1);
    resetLevel(0);
    mobileButton.setAttribute("aria-expanded", "false");
    mobileSearch.classList.remove("show");
  }

  const closeButton = menu.querySelector(".navigation-main-close");
  closeButton.addEventListener("click", closeMenu);

  const collapseButtons = menu.querySelectorAll(".navigation-main-collapse");
  collapseButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
      switch (button.dataset.level) {
        case "2":
          resetLevel(2, true);
          break;
        case "1":
          resetLevel(2);
          resetLevel(1, true);
          break;
      }
      e.stopPropagation();
    });
  });

  mobileButton.addEventListener("click", () => {
    const expanded = mobileButton.getAttribute("aria-expanded") === "true";
    if (expanded) {
      closeMenu();
    } else {
      showItem(mobileButton.dataset.id, 0);
    }
    mobileButton.setAttribute("aria-expanded", (!expanded).toString());
  });

  setTimeout(() => {
    // remove home special design on scroll
    if (header) {
      window.addEventListener("scroll", (e) => {
        if (window.scrollY > 5) {
          header.classList.remove("home");
        }
      });
    }
  }, 50);

  document.querySelector(".navigation-main--overlay").addEventListener("click", (e) => {
    if (e.target === e.currentTarget) {
      closeMenu();
    }
  });
}

export default initMegaMenu;
