export default function initTabArrows() {
    const containerTabbed = document.querySelectorAll(".container-horizontal-scroll")
    containerTabbed.forEach((container) => {
        const nav = container.querySelector(".container-scrollable-nav");
        const buttonLeft = container.querySelector(".container-scrollable-left");
        const buttonRight = container.querySelector(".container-scrollable-right");
        const tabWrapper = container.querySelector(".container-scrollable-wrapper");
        const scrollWidth = tabWrapper.getBoundingClientRect().width / 3 * 2;

        if(tabWrapper.scrollWidth <= tabWrapper.getBoundingClientRect().width) {
            nav.classList.add("hidden");
        }

        buttonLeft.addEventListener("click", () => {
            tabWrapper.scrollBy({
                left: -scrollWidth,
                behavior: "smooth"
            });
        });

        buttonRight.addEventListener("click", () => {
            tabWrapper.scrollBy({
                left: +scrollWidth,
                behavior: "smooth"
            });
        });

        tabWrapper.addEventListener("scroll", () => {
            if (tabWrapper.scrollLeft < 2) {
                buttonLeft.disabled = true;
            } else if (tabWrapper.getBoundingClientRect().width + tabWrapper.scrollLeft + 2 > tabWrapper.scrollWidth) {
                buttonRight.disabled = true;
            } else {
                buttonRight.disabled = false;
                buttonLeft.disabled = false;
            }
        })
    })

    window.addEventListener("resize", () => {
        // hide buttons if not needed
        containerTabbed.forEach((container) => {
            const nav = container.querySelector(".container-scrollable-nav");
            const tabWrapper = container.querySelector(".container-scrollable-wrapper");
            if(tabWrapper.scrollWidth <= tabWrapper.getBoundingClientRect().width) {
                nav.classList.add("hidden");
            } else {
                nav.classList.remove("hidden");
            }
        })
    })
}
