function elementInViewport(el) {
  if (!el) {
    return false;
  }
  const rect = el.getBoundingClientRect();
  return rect.top > 0 && rect.top < innerHeight;
}
export function addAnimation(elements, animation) {
  elements.forEach((el, index) => {
    if (elementInViewport(el) && !el.style.animation) {
      setTimeout(() => {
        el.style.animation = animation;
        el.style.visibility = "visible";
      }, 200 * Math.floor(index / 3));
    }
  });
}

export function removeAnimation(elements) {
  elements.forEach((el, index) => {
    el.style.animation = "";
    el.style.visibility = "hidden";
  });
}

export function fireScrollAnimation(elements, animation) {
  addAnimation(elements, animation);

  document.addEventListener("scroll", () => {
    addAnimation(elements, animation);
  });
}

export function logoScrollAnimation(animation) {
  document.querySelectorAll(".gallery--logos").forEach((gallery) => {
    if (elementInViewport(gallery) && !gallery.style.animation) {
      gallery.style.animation = animation;
    }
    document.addEventListener("scroll", () => {
      if (elementInViewport(gallery) && !gallery.style.animation) {
        gallery.style.animation = animation;
      }
    });
  });
}
